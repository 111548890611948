import React, { useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

import prevIcon from '../../img/carousel_leftarrow.svg';
import nextIcon from '../../img/carousel_rightarrow.svg';

const StyledCarousel = styled.div`
	margin-bottom: 3rem;

	.slider {
		@media (max-width: 768px) {
			padding: 0 10%;
		}
	}

	.slider-list {
		.slider-slide {
			/* top: 50% !important; */
			transform: scale(0.9) !important;

			&.slide-visible {
				transform: scale(1) !important;
			}

			@media (max-width: 768px) {
				display: none !important;

				&.slide-visible {
					display: inline-block !important;
				}
			}
		}
	}

	.control-button {
		width: 3rem;
		height: 2.5rem;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		border: none;
		cursor: pointer;

		@media (max-width: 599px) {
			width: 1.8rem;
			height: 1.5rem;
		}
	}
`;

const ListingImagesCarousel = ({ images }) => {
	// Fire a resize to try and fix carousel height issue
	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	});

	return (
		<StyledCarousel>
			<Carousel
				enableKeyboardControls={true}
				heightMode={'max'}
				wrapAround={true}
				frameOverflow={'visible'}
				cellSpacing={0}
				slideWidth={1}
				renderCenterLeftControls={({ previousSlide }) => (
					<button
						className="control-button prev-button"
						onClick={() => previousSlide()}
						style={{ backgroundImage: `url(${prevIcon})` }}
					>
						<span className="visually-hidden">Previous Slide</span>
					</button>
				)}
				renderCenterRightControls={({ nextSlide }) => (
					<button
						className="control-button next-button"
						onClick={() => nextSlide()}
						style={{ backgroundImage: `url(${nextIcon})` }}
					>
						<span className="visually-hidden">Next Slide</span>
					</button>
				)}
				renderBottomCenterControls={() => null}
			>
				{images &&
					images.map((item, i) => {
						if (typeof item === 'string') {
							return (
								<div
									className="columns is-centered is-vcentered"
									key={`${item}-${i}`}
								>
									<div className="column is-10">
										<img src={item} alt="" />
									</div>
								</div>
							);
						}

						return (
							<div
								className="columns is-centered is-vcentered"
								key={`${
									item.image && item.image.childImageSharp
										? item.image.childImageSharp.fluid.src
										: item.image
								}-${i}`}
							>
								<div className="column is-10">
									<PreviewCompatibleImage imageInfo={item} />
								</div>
							</div>
						);
					})}
			</Carousel>
		</StyledCarousel>
	);
};

export default ListingImagesCarousel;
