import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tile = styled.div`
  flex-basis: 33.33%;
  text-align: center;
  padding: 20px;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
	  flex-basis: 100%;
	}
  img {
    margin-bottom: 20px;
  }
  h4 {
    color: #272727;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
  h3 {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 22px;
    color: #957831;
    margin: 15px 0 25px 0;
  }
  a {
    padding: 6px 15px;
    font-size: 16px;
    color: #50101A;
    border: 1px solid #50101A;
    &:hover {
      color: #fff;
      background-color: #957831;
      border: 1px solid #957831;
    }
  }
`;

const CommunityTiles = ({ source }) => {

  return (
    <Wrapper>
      {source.map((item) => (
        <Tile>
          <img src={item.logo} alt={item.name} />
          <h4>{item.category}</h4>
          <h3>{item.name}</h3>
          <a href={item.link}>WEBSITE</a>
        </Tile>
      ))}
    </Wrapper>);
}

export default CommunityTiles;