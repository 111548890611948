import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Link from 'gatsby-link';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import ListingImagesCarousel from '../components/carousels/ListingImagesCarousel';

import { IconCards } from '../components/icon-cards';
import StyledIntro from '../styles/StyledIntro';
import theme from '../styles/theme/theme';

import mdToHtml from '../util/mdToHtml';
import CommListFromFeed from '../components/listings/CommListFromFeed';

import CommPageBlogs from '../components/blog/CommPageBlogs';
import introBg from '../img/sell-your-home/Sell_HeaderBG.jpg';

import domIcon from '../img/community/avg_days_on_market.png';
import rentalIcon from '../img/community/avg_rental_price.png';
import saleIcon from '../img/community/avg_sale_price.png';
import popIcon from '../img/community/population.png';
import unitsIcon from '../img/community/units_sold.png';
import CommunityTiles from '../components/community/CommunityTiles';
import Blogs from '../components/blog/Blogs';
import Favs from '../components/community/Favs';

const Page = styled.article`
	scroll-behavior: smooth !important;
  .two-column-section {
		padding-top: 9rem;
		padding-bottom: 9rem;

		.grid {
			display: grid;
			grid-template-columns: 1.2fr 1fr;
			column-gap: 50px;

			.content-container {
				max-width: 44rem;
				margin-bottom: 3rem;

				@media (min-width: 769px) {
					margin-bottom: 0;
				}

				.heading {
					margin-bottom: 1em;
					color: ${(p) => p.theme.colors.tertiary};
				}
			}
		}

		@media (max-width: 999px) {
			padding-bottom: 3.5rem;

			.grid {
				grid-template-columns: 1fr;
				grid-template-rows: auto 1fr;
				row-gap: 32px;
			}
		}
	}
  .section-head {
    text-align: center;
    font-size: 20px;
    margin: 50px 0;
  }
  .intro-section {
    .button {
      margin: 25px 0;
    }
    .dropper {
      color: #A28C44;
      font-weight: 600;
      font-size: 16px;
      display: block;
      text-align: center;
    }
  }
  .recent-posts-section {
    background-image: url(${introBg});
	  background-size: cover;
	  background-position: center;
	  background-repeat: no-repeat;
    padding-bottom: 50px;
    h3 {
      text-align: center;
      font-size: 32px;
      margin: 50px;
      font-family: ${(props) => props.theme.fonts.secondary};
      font-weight: bold;
    }
  }
  .more-arrow {
		display: block;
		margin: 0 auto;
		background: none;
		border: none;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;

		&[disabled] {
			display: none;
		}

		&::before {
			content: '';
			width: 1px;
			height: 1rem;
			background-color: ${(props) => props.theme.colors.tertiary};
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%);
		}

		&::after {
			content: '';
			width: 0.75rem;
			height: 0.75rem;
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%) rotate(45deg);
			border-right: 1px solid ${(props) => props.theme.colors.tertiary};
			border-bottom: 1px solid ${(props) => props.theme.colors.tertiary};
		}
	}
`;

export const CommunityTemplate = ({ comm, blogs, favs }) => {
  //console.log(comm);
  return (
    <Page>
      <StyledIntro
        className="section intro-section"
        color={theme.colors.tertiary}
      >
        <div className="container">
          <div className="grid two-columns">
            <div className="grid-column">
              <div className="intro-content-container">
                <h1 className="heading">Welcome to {comm.name}!</h1>
                <AccentLine color={theme.colors.primary} />
                <div className="intro-image-wrapper mobile">
                  <img src={comm.masthead.masthead_image} alt={comm.name} style={{ display: 'block' }} />
                </div>
                <div
                  className="intro-text"
                  dangerouslySetInnerHTML={{
                    __html: mdToHtml(comm.masthead.masthead_blurb),
                  }}
                />
                <Link className="button" to="#contact" style={{ textTransform: 'uppercase' }}>
                  NOTIFY ME ABOUT {comm.name} LISTINGS
                </Link>
                <br />
                <a className="dropper" href="#stats">READ MORE <div className="more-arrow"></div> </a>
              </div>
            </div>
            <div className="grid-column">
              <div className="intro-image-wrapper desktop">
                <img src={comm.masthead.masthead_image} alt={comm.name} style={{ display: 'block' }} />
              </div>
            </div>
          </div>
        </div>
      </StyledIntro>
      <section className="section stats-section" style={{ backgroundColor: '#f8f8f8' }} id="stats">
        <div className="container">
          <h2 className="section-head">KEY STATISTICS</h2>
          <div className="columns">
            <div className="column">
              <IconCards columns={3} cards={[
                {
                  icon: { publicURL: popIcon },
                  text: "POPULATION",
                  big: comm.stats.population,
                },
                {
                  icon: { publicURL: saleIcon },
                  text: "AVERGAE SALE PRICE",
                  big: comm.stats.asp,
                  detail: comm.stats.asp_detail
                },
                {
                  icon: { publicURL: rentalIcon },
                  text: "AVERAGE RENTAL PRICE",
                  big: comm.stats.arp,
                  detail: comm.stats.arp_detail
                },
                {
                  icon: { publicURL: domIcon },
                  text: "AVERAGE DAYS ON MARKET",
                  big: comm.stats.dom,
                },
                {
                  icon: { publicURL: unitsIcon },
                  text: `UNITS SOLD IN ${comm.stats.year}`,
                  big: comm.stats.units_sold,
                },
              ]} />
            </div>
          </div>
        </div>
      </section>

      <section className="section listings-section">
        <div className="container">
          <h2 className="section-head">{comm.name} HOMES FOR SALE</h2>
          <div className="columns">
            <div className="column">
              {/*<CommList listings={listings} orderingList={orderingList} />*/}
              <CommListFromFeed city={comm.name} />
            </div>
          </div>
        </div>
      </section>

      <section className="section lens-section" style={{ backgroundColor: '#f8f8f8' }}>
        <div className="container">
          <h2 className="section-head">COMMUNITY LENS</h2>
          <div className="columns carousel-row is-centered">
            <div className="column is-10">
              <ListingImagesCarousel images={comm.gallery} />
            </div>
          </div>
        </div>
      </section>
      <section className="section schools-section">
        <div className="container">
          <h2 className="section-head">LOCAL SCHOOLS</h2>
          <CommunityTiles source={comm.schools} />
        </div>
      </section>
      <section className="section municipal-section" style={{ backgroundColor: '#f8f8f8' }}>
        <div className="container">
          <h2 className="section-head">MUNICIPAL PROVIDERS</h2>
          <CommunityTiles source={comm.municipal_partners} />
        </div>
      </section>
      <section className="section favs-section">
        <div className="container">
          <h2 className="section-head">LOCAL FAVOURITES</h2>
          <Favs favs={favs} />
        </div>
      </section>
      <section className="section recent-posts-section img-bg">
        <div className="container">
          <h2 className="section-head">BLOG</h2>
          <h3>Community Voices</h3>
          <Blogs posts={blogs} />
        </div>
      </section>
    </Page>
  );
};

const Community = ({ data }) => {
  console.log(data);
  const { frontmatter, fields } = data.markdownRemark;
  const blogs = data.blogs.edges;
  const favs = frontmatter.favs;
  return (
    <Layout>
      <SEO
        title={frontmatter.name}
        /*
        image={frontmatter.featured_image.image}
        description={frontmatter.description.heading}
        */
        slug={fields.slug}
      />
      <CommunityTemplate
        comm={frontmatter}
        blogs={blogs}
        favs={favs}
      />
    </Layout>
  );
};

export default Community;

export const communityQuery = graphql`
  query Community($id: String!, $community: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        name
        masthead {
          masthead_title
          masthead_blurb
          masthead_image
        }
        stats {
          population
          asp
          asp_detail
          arp
          arp_detail
          dom
          units_sold
          year
        }
        gallery {
          image
          title
        }
        schools {
          name
          category
          logo
          link
        }
        municipal_partners {
          name
          category
          logo
          link
        }
        favs {
          name
          category
          logo
          image
          link
        }
      }
    }
    blogs: allMarkdownRemark(filter: {frontmatter: {template_key: {eq: "blog-post"}, tags: {eq: $community}}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          fields {
            slug
          }
          frontmatter {
            title
            author
            featured_image {
              alt
              image
            }
          }
        }
      }
    }
  }
`;