import React from 'react';
import styled from 'styled-components';
import FavsCarousel from '../carousels/FavsCarousel'

const Wrapper = styled.div``;
const Favs = ({ favs }) => {
  return (
    <Wrapper>
      <FavsCarousel favs={favs} />
    </Wrapper>);
}

export default Favs;