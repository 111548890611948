import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import leftArrow from '../../img/carousel_leftarrow.svg';
import rightArrow from '../../img/carousel_rightarrow.svg';

const StyledCarousel = styled.div`
	.columns {
		h4 {
			color: #272727;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
		}
		h3 {
			font-family: ${(props) => props.theme.fonts.secondary};
			font-size: 22px;
			color: #957831;
			margin: 15px 0 15px 0;
		}
		a {
			padding: 6px 15px;
			font-size: 16px;
			color: #50101A;
			border: 1px solid #50101A;
			&:hover {
				color: #fff;
				background-color: #957831;
				border: 1px solid #957831;
			}
		}
		.logo {
			display: block;
			margin: 50px auto 50px auto;
		}
		.details {
			width: 100%;
			height: 100%;
			padding: 125px;
			@media (max-width: 1024px) {
				padding: 25px;
			}
		}
	}

	.slider-control-bottomcenter {
		position: relative;
		width: 40rem;
		margin: 0 auto;
		max-width: 100%;
		bottom: -3rem !important;

		@media (max-width: 1087px) {
			padding: 0 1.9rem;
		}

		.nav-button {
			width: 2.1rem;
			height: 1.8rem;
			background-color: transparent;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			border: none;
			cursor: pointer;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&.prev-button {
				background-image: url(${leftArrow});
				left: 0;
			}

			&.next-button {
				background-image: url(${rightArrow});
				right: 0;
			}
		}

		.indicator-bar {
			width: 30rem;
			max-width: 90%;
			height: 3px;
			background-color: ${(props) => props.theme.colors.textDarkerGrey};
			margin: 0 auto;

			@media (max-width: 599px) {
				/* display: none; */
			}

			.bar {
				width: calc(100% / ${(props) => props.testimonialsLength});
				height: 3px;
				background-color: ${(props) => props.theme.colors.primary};
				transition: 0.4s;
				top: 0px;
				position: relative;
			}
		}
	}
`;

const FavsCarousel = ({ favs }) => {
	const [slide, setSlide] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	});

	return (
		<StyledCarousel testimonialsLength={favs.length} current={slide}>
			<Carousel
				heightMode={'max'}
				wrapAround={true}
				autoplay={true}
				autoplayInterval={10000}
				// slideIndex={slide}
				// afterSlide={(prevSlide) => setSlide(prevSlide)}
				renderCenterLeftControls={() => null}
				renderCenterRightControls={() => null}
				renderBottomCenterControls={({
					currentSlide,
					previousSlide,
					nextSlide,
				}) => (
					<>
						<button
							className="nav-button prev-button"
							onClick={() => previousSlide()}
						>
							<span className="visually-hidden">Previous Slide</span>
						</button>
						<div className="indicator-bar">
							<div
								className="bar"
								style={{
									left: `calc((100% / ${favs.length
										}) * ${currentSlide})`,
								}}
							/>
						</div>
						<button
							className="nav-button next-button"
							onClick={() => nextSlide()}
						>
							<span className="visually-hidden">Next Slide</span>
						</button>
					</>
				)}
			>
				{favs.map((item, i) => (
					<div key={`${item.name}-${i}`} className="columns">
						<div className="column is-7">
							<img src={item.image} alt={item.name} style={{ width: '100%' }} />
						</div>
						<div className="column is-5">
							<div className="has-text-centered details">
								<h4>{item.category}</h4>
								<h3>{item.name}</h3>
								<img src={item.logo} alt={item.name} className="logo" />
								<a href={item.link}>WEBSITE</a>
							</div>
						</div>
					</div>
				))}
			</Carousel>
		</StyledCarousel>
	);
};

export default FavsCarousel;
